<script>
// import axios from "redaxios";
import { processToken, getSituacionIcon } from "@/utils/index.js";
import { Doughnut } from "vue-chartjs";
import colors from "vuetify/lib/util/colors";

export default {
	extends: Doughnut,
	data() {
		return {
			situacionesContratos: null,
		};
	},
	computed: {
		options() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					position: "bottom",
					labels: {
						usePointStyle: true,
					},
				},
				title: {
					display: true,
					text: `Situacion de los contratos total: ${this.situacionesContratos.totalContratos}`,
				},
			};
		},
	},
	async mounted() {
		let str = this.$store.getters.getDatosEmpresa;

		const { IdUsuario, IdAgente } = str.iss;
		const IdEmpresa = this.$store.getters.getIdEmpresa;

		let { data: contratos } = await axios({
			method: "GET",
			url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
			params: {
				token: this.$store.getters.getJwtEmpresa,
				IdAgente,
				count: true,
			},
		});
		contratos = contratos.filter((c) => c.Situacion != "Anulado");

		let { data: borradores } = await axios({
			method: "GET",
			url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
			params: {
				token: this.$store.getters.getJwtEmpresa,
				entorno: this.$store.getters.getEntorno,
				count: true,
			},
		});

		let datos = [...contratos, ...borradores.filter(b => b.estado == 'Borrador')];
		const totalContratos = datos.reduce((t, a) => t + Number(a.numero), 0);

		const getColor = (label) => {
			let col = getSituacionIcon(label).color;
			let finalColor = col;
			try {
				if (!/#/g.test(col))
					finalColor =
						this.$vuetify.theme.themes.light[col] || colors[col]["base"];
			} catch (e) {
				finalColor = col;
			}
			return finalColor;
		};

		let results = {
			labels: [],
			datasets: [
				{
					data: [],
					hoverBackgroundColor: [],
					backgroundColor: [],
				},
			],
		};

		datos.forEach(({ numero, estado, Situacion }, index) => {
			Situacion = Situacion || estado;
			results.labels[index] = Situacion;
			let color = getColor(Situacion);
			results.datasets[0].data[index] = numero;
			results.datasets[0].hoverBackgroundColor[index] = color;
			results.datasets[0].backgroundColor[index] = color + "a0";
		});

		this.situacionesContratos = { totalContratos, datos: results };
		this.renderChart(this.situacionesContratos.datos, this.options);
		this.$emit("loaded");
	},
};
</script>

<style>
</style>